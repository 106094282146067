import { useEffect, useState } from 'react';
import { GrantService } from '../services/GrantService';

interface FetchGrantsContainerProps {
  id: number;
  children(grants: any[], loading: boolean): JSX.Element;
  onError(error: { message: string; reason: string }): void;
}

const FetchGrantsContainer = (
  props: FetchGrantsContainerProps,
): JSX.Element => {
  const service = new GrantService();

  const [grants, setGrants] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (grants.length === 0) {
      setLoading(true);
      service
        .getGrants(props.id)
        .then((grants) => {
          setGrants(grants);
          setLoading(false);
        })
        .catch((e: Error) => {
          console.error(e);

          if (e.message === 'Unauthorized') {
            props.onError({ message: 'error.unauthorized', reason: e.message });
          } else {
            props.onError({ message: 'error.unknown', reason: e.message });
          }
        });
    }
  }, []);

  return props.children(grants, loading);
};

export { FetchGrantsContainer };
export type { FetchGrantsContainerProps };
