import { LoginApp } from '@frontend/busit-ui/lib/apps/login/LoginApp';
import {
  UserContext,
  UserContextProvider,
} from '@frontend/busit-ui/lib/contexts/UserContext';
import React from 'react';
import './App.css';
import { RedirectApp } from './RedirectApp';

/**
 *
 *
 * @return {*}
 */
function App(): JSX.Element {
  return (
    <UserContextProvider>
      <UserContext.Consumer>
        {({ user, token }) => {
          if (user === undefined || token === undefined) {
            return <React.Fragment />;
          } else if (user === null || token === null) {
            return <LoginApp useBackground />;
          } else {
            return <RedirectApp />;
          }
        }}
      </UserContext.Consumer>
    </UserContextProvider>
  );
}

export default App;
