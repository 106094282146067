const translations: any = {
  fr: {
    redirect: {
      identification: 'Authentifié en tant que :',
      logout: 'Se déconnecter',
      tools: 'Outils',
      applications: 'Applications',
      disconnecting: 'Déconnexion dans %{seconds}s.',
    },
    error: {
      text: 'Erreur',
      unknown: "Une erreur inattendue s'est produite.",
      unauthorized: 'La session a expiré.',
      disconnecting: 'Déconnexion dans %{seconds}s. ',
      unavailable: 'Un service est indisponible.',
    },
  },
  en: {
    redirect: {
      identification: 'Logged in as:',
      logout: 'Logout',
      tools: 'Tools',
      applications: 'Applications',
    },
    error: {
      text: 'Error',
      unknown: 'An unexpected error occured.',
      unauthorized: 'Session is expired.',
      disconnecting: 'Disconnecting in %{seconds}s. ',
      unavailable: 'A service is unavailable est indisponible.',
    },
  },
};

export default translations;
