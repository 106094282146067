import { Service } from '@frontend/busit-ui/lib/services/Service';

/**
 *
 *
 * @class GrantService
 */
class GrantService extends Service {
  private url: string;

  /**
   * Creates an instance of GrantService.
   * @memberof GrantService
   */
  constructor() {
    super();
    this.url = `${window.location.protocol}//${window.location.host}/api/self/system/grant/user/select`;
  }

  /**
   *
   *
   * @param {number} userId
   * @return {*}  {Promise<void>}
   * @memberof GrantService
   */
  public async getGrants(userId: number): Promise<string[]> {
    const body = new FormData();

    body.append('user', userId.toString());
    body.append('all', 'true');

    const params: RequestInit = {
      credentials: 'include', // I send explicitly the cookies
      method: 'POST',
      body,
    };

    try {
      const response = await this.fetch(this.url, params);

      if (response.status === 503) {
        throw new Error('error.unavailable');
      }

      if (response.status === 200) {
        const resBody = await response.json();
        return resBody.response.map(
          // eslint-disable-next-line camelcase
          (grant: { grant_id: number; grant_name: string }) => grant.grant_name,
        );
      }
      throw response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

export { GrantService };
