import { useEffect, useState } from 'react';
import { UserService } from '../services/UserService';

interface FetchUserContainerProps {
  children(user?: { name: string }): JSX.Element;
  onError(error: { message: string; reason: string }): void;
}

const FetchUserContainer = (props: FetchUserContainerProps): JSX.Element => {
  const service = new UserService();

  const [user, setUser] = useState<{ name: string } | undefined>(undefined);

  useEffect(() => {
    service
      .getUser()
      .then((res) => {
        setUser(res);
      })
      .catch((e: Error) => {
        console.error(e);

        if (e.message === 'Unauthorized') {
          props.onError({ message: 'error.unauthorized', reason: e.message });
        } else {
          props.onError({ message: 'error.unknown', reason: e.message });
        }
      });
  }, []);

  return props.children(user);
};

export { FetchUserContainer };
export type { FetchUserContainerProps };
