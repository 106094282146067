import { useEffect, useState } from 'react';
import { BusinessappService } from '../services/BusinessappService';

interface FetchBusinessappsContainerProps {
  user: number;
  children(businessapps: any[], loading: boolean): JSX.Element;
  onError(error: { message: string; reason: string }): void;
}

const FetchBusinessappsContainer = (
  props: FetchBusinessappsContainerProps,
): JSX.Element => {
  const service = new BusinessappService();
  const [businessapps, setBusinessapps] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (businessapps.length === 0) {
      service
        .getBusinessapps(props.user)
        .then((businessapps) => {
          setBusinessapps(businessapps);
          setLoading(false);
        })
        .catch((e: Error) => {
          console.error(e);

          if (e.message.includes('Unauthorized')) {
            props.onError({ message: 'error.unauthorized', reason: e.message });
          } else {
            props.onError({ message: 'error.unknown', reason: e.message });
          }
        });
    }
  }, []);

  return props.children(businessapps, loading);
};

export { FetchBusinessappsContainer };
export type { FetchBusinessappsContainerProps };
