import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { WhiteBox } from '@frontend/busit-ui/lib/components/surfaces/WhiteBox';
import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';

interface WebappLinkProps {
  title: string;
  descriptions: string[];
  href: string;
}

const WebappLink = (props: WebappLinkProps) => {
  const handleClick = () => {
    window.location.replace(`https://${window.location.host}${props.href}`);
  };

  const handleMiddleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.open(`https://${window.location.host}${props.href}`);
  };

  return (
    <WhiteBox
      onClick={handleClick}
      onAuxClick={handleMiddleClick}
      style={{
        border: 'none',
        backgroundColor: 'rgba(255,255,255,0.8)',
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignContent={'flex-start'}
      >
        <Grid item>
          <Typography>{props.title}</Typography>
          <Typography variant="caption">{props.href}</Typography>
        </Grid>
        <Grid item>
          <IconButton
            title="open in new tab"
            size="small"
            onClick={handleMiddleClick}
          >
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </WhiteBox>
  );
};

export { WebappLink };
export type { WebappLinkProps };
