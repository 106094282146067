import { Service } from '@frontend/busit-ui/lib/services/Service';

/**
 *
 *
 * @class UserService
 */
class UserService extends Service {
  private url: string;

  /**
   * Creates an instance of UserService.
   * @memberof UserService
   */
  constructor() {
    super();
    this.url = `${window.location.protocol}//${window.location.host}/api/self/vanilla/profile/select`;
  }

  /**
   *
   *
   * @param {string} identifier
   * @return {*}  {Promise<number>}
   * @memberof UserService
   */
  public async getUser(): Promise<{ name: string }> {
    const headers = {
      'Content-Type': 'application/json',
    };

    const params = {
      method: 'GET',
      headers,
    };

    const response = await this.fetch(this.url, params);

    if (response.status === 503) {
      throw new Error('error.unavailable');
    }

    const resBody = await response.json();
    return {
      name: resBody.response.user_name,
    };
  }
}

export { UserService };
