import React, { useContext, useEffect, useState } from 'react';
import { WebappLink } from './components/WebappLink';
import { FetchBusinessappsContainer } from './containers/FetchBusinessappsContainer';
import { FetchGrantsContainer } from './containers/FetchGrantsContainer';
import { FetchUserContainer } from './containers/FetchUserContainer';
import busitLogo from '@frontend/busit-ui/lib/assets/logo_busit.png';
import background from '@frontend/busit-ui/lib/assets/login_background.jpg';
import translations from './translations/translations';
import { UserContext } from '@frontend/busit-ui/lib/contexts/UserContext';
import { BusitThemeProvider } from '@frontend/busit-ui/lib/theme/BusitThemeProvider';
import {
  LocaleContext,
  LocaleContextProvider,
} from '@frontend/busit-ui/lib/contexts/LocaleContext';
import { deleteCookie } from '@frontend/busit-ui/lib/utils/CookieUtils';
import { AuthService } from '@frontend/busit-ui/lib/services/auth/AuthService';
import { WhiteBox } from '@frontend/busit-ui/lib/components/surfaces/WhiteBox';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Typography,
  Skeleton,
  Button,
  Snackbar,
  Alert,
  SnackbarCloseReason,
} from '@mui/material';
import Polyglot from 'node-polyglot';

const withStyle = makeStyles({
  root: {
    height: '100%',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    overflowY: 'scroll',
  },
});

const errorDuration = 6000; // milliseconds

const RedirectApp = (): JSX.Element => {
  const { setUser: setAuth, user, setToken } = useContext(UserContext);
  const [openError, setOpenError] = useState<
    { message: string; reason: string } | undefined
  >(undefined);
  const [secondsBeforeDisconnection, setSecondsBeforeDisconnection] = useState<
    number | undefined
  >(undefined);
  const [interval, setIntervalRef] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );

  const classes = withStyle();

  if (!user) {
    throw new Error(
      'user is undefined, this component should not render if this is the case',
    );
  }

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [interval]);

  useEffect(() => {
    if (secondsBeforeDisconnection === 0) {
      setAuth(null);
      setToken(null);
      deleteCookie('id');
      deleteCookie('token');
      setSecondsBeforeDisconnection(undefined);
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [secondsBeforeDisconnection, interval]);

  useEffect(() => {
    if (openError && openError.reason !== 'error.unavailable') {
      setIntervalRef(
        setInterval(() => {
          setSecondsBeforeDisconnection((duration) => {
            if (duration === undefined) {
              duration = errorDuration / 1000;
            } else {
              duration--;
            }
            return duration;
          });
        }, 1000),
      );
    }
  }, [openError]);

  const handleCloseError = (
    event: Event | React.SyntheticEvent,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    if (interval) {
      clearInterval(interval);
    }

    if (openError?.reason !== 'error.unavailable') {
      setAuth(null);
      setToken(null);
      deleteCookie('id');
      deleteCookie('token');
    }

    setOpenError(undefined);
  };

  const handleError = (error: { message: string; reason: string }) => {
    setOpenError(error);
  };

  const buildAlertMessage = (
    translate: (
      phrase: string,
      options?: number | Polyglot.InterpolationOptions,
    ) => string,
  ) => {
    return (
      (secondsBeforeDisconnection !== undefined
        ? ' ' +
          translate('error.disconnecting', {
            seconds: secondsBeforeDisconnection - 1,
          })
        : '') +
      (openError
        ? translate(openError.message) +
          '\n' +
          translate('error.text') +
          ': ' +
          translate(openError.reason)
        : translate('error.unknown'))
    );
  };
  return (
    <BusitThemeProvider>
      <LocaleContextProvider translations={translations}>
        <LocaleContext.Consumer>
          {({ translate }) => (
            <>
              <Grid
                className={classes.root}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={10}
                  lg={8}
                  style={{
                    height: 'fit-content',
                    margin: '5% 0',
                  }}
                >
                  <WhiteBox
                    style={{
                      backdropFilter: 'blur(3px)',
                      backgroundColor: 'rgba(255,255,255,0.9)',
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      style={{
                        height: '100%',
                        alignItems: 'start',
                      }}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6} style={{}}>
                        <Grid
                          container
                          direction="column"
                          spacing={2}
                          justifyContent="center"
                          wrap="nowrap"
                        >
                          <Grid item>
                            <img
                              src={busitLogo}
                              alt="logo"
                              style={{ height: '120px' }}
                            />
                          </Grid>
                          <FetchUserContainer onError={handleError}>
                            {(user) => (
                              <Grid item>
                                <Typography variant="h4">
                                  {translate('redirect.identification')}
                                </Typography>
                                {user ? (
                                  <Typography variant="h4">
                                    {user.name}
                                  </Typography>
                                ) : (
                                  <Skeleton>
                                    <Typography component="div" variant="h4">
                                      user
                                    </Typography>
                                  </Skeleton>
                                )}
                              </Grid>
                            )}
                          </FetchUserContainer>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                const service = new AuthService();
                                service.logout().then(() => {
                                  setAuth(null);
                                  setToken(null);
                                  deleteCookie('id');
                                  deleteCookie('token');
                                });
                              }}
                            >
                              {translate('redirect.logout')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        style={{
                          height: '100%',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          spacing={2}
                          justifyContent="center"
                        >
                          <Grid item>
                            <Grid container direction="column" spacing={1}>
                              <FetchGrantsContainer
                                onError={handleError}
                                id={user}
                              >
                                {(grants, loading) => {
                                  const links = [];

                                  if (loading) {
                                    return (
                                      <Skeleton
                                        key="loading skeleton"
                                        height={100}
                                      />
                                    );
                                  }

                                  if (
                                    grants.includes('SUPERADMIN') ||
                                    grants.includes('ADMIN')
                                  ) {
                                    links.push(
                                      <Grid key="admin redirect" item>
                                        <WebappLink
                                          title="Admin"
                                          descriptions={[
                                            'Administrate the platform',
                                          ]}
                                          href={'/admin'}
                                        />
                                      </Grid>,
                                    );
                                  }
                                  // This grant is not enough but will do the work for now
                                  // Check if user is designer
                                  if (grants.includes('SELF_INSTANCE_INSERT')) {
                                    links.push(
                                      <Grid key="home redirect" item>
                                        <WebappLink
                                          title="Home"
                                          descriptions={['Design']}
                                          href={'/home'}
                                        />
                                      </Grid>,
                                    );
                                    links.push(
                                      <Grid key="dashboard redirect" item>
                                        <WebappLink
                                          title="Dashboard"
                                          descriptions={['Design']}
                                          href={'/dashboard'}
                                        />
                                      </Grid>,
                                    );
                                  }

                                  if (links.length) {
                                    links.unshift(
                                      <Grid key="title" item>
                                        <Typography variant="h5">
                                          {translate('redirect.tools')}
                                        </Typography>
                                      </Grid>,
                                    );
                                  }

                                  return (
                                    <React.Fragment>{links}</React.Fragment>
                                  );
                                }}
                              </FetchGrantsContainer>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container direction="column" spacing={1}>
                              <Grid item>
                                <Typography variant="h5">
                                  {translate('redirect.applications')}
                                </Typography>
                              </Grid>
                              <FetchBusinessappsContainer
                                onError={handleError}
                                user={user}
                              >
                                {(businessapps, loading) => {
                                  return (
                                    <React.Fragment>
                                      {loading ? (
                                        [1, 2, 3].map((num) => (
                                          <Skeleton key={num} height={100} />
                                        ))
                                      ) : (
                                        <React.Fragment>
                                          {businessapps.map((item, index) => (
                                            <Grid item key={index}>
                                              <WebappLink
                                                title={item.businessapp_name}
                                                descriptions={[
                                                  'id#' + item.businessapp_id,
                                                ]}
                                                href={
                                                  '/' +
                                                  item.webapp_path +
                                                  '/?id=' +
                                                  item.businessapp_id
                                                }
                                              />
                                            </Grid>
                                          ))}
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  );
                                }}
                              </FetchBusinessappsContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </WhiteBox>
                </Grid>
              </Grid>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={openError !== undefined}
                autoHideDuration={errorDuration}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {buildAlertMessage(translate)}
                </Alert>
              </Snackbar>
            </>
          )}
        </LocaleContext.Consumer>
      </LocaleContextProvider>
    </BusitThemeProvider>
  );
};

export { RedirectApp };
