import { Service } from '@frontend/busit-ui/lib/services/Service';

/**
 *
 *
 * @class BusinessappService
 */
class BusinessappService extends Service {
  private url: string;

  /**
   * Creates an instance of BusinessappService.
   * @memberof BusinessappService
   */
  constructor() {
    super();
    this.url = `${window.location.protocol}//${window.location.host}/api/curry/analytics/businessapp/select`;
  }

  /**
   *
   *
   * @param {number} userId
   * @return {*}  {Promise<void>}
   * @memberof BusinessappService
   */
  public async getBusinessapps(userId: number): Promise<any[]> {
    const body = new FormData();

    body.append('user', userId.toString());

    const params: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body,
    };

    let response = await this.fetch(this.url, params);

    if (response.status === 503) {
      throw new Error('error.unavailable');
    }

    if (response.status === 403) {
      await this.refresh();
      response = await this.fetch(this.url, params);
    }

    const resBody = await response.json();
    if (resBody.error !== undefined) {
      return [];
    }
    return resBody.response.map(
      // eslint-disable-next-line camelcase
      (businessapp: any) => {
        return {
          businessapp_id: businessapp.businessapp_id,
          businessapp_name: businessapp.businessapp_name,
          webapp_path: businessapp.webapp_path,
        };
      },
    );
  }
}

export { BusinessappService };
